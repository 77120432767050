import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import find from "lodash.find"

// Import Global Context(s)
import UserContext from "~context/user"

/* Import Global Components */
import Page from "~components/page"
import Link from "~components/link"

/* Import Local Styles */
import "./author.scss"

const AuthorTemplate = ({ data: { sanityAuthor: author } }) => {
  const [isAuthorSaved, setIsAuthorSaved] = useState(false)
  const [user, { saveAuthor, removeAuthor }] = useContext(UserContext)

  useEffect(() => {
    if (user && author && user.research && user.research.authors) {
      const isAuthorSaved =
        find(user.research.authors, savedAuthor => {
          return savedAuthor.id === author.id
        }) !== undefined
      setIsAuthorSaved(isAuthorSaved)
    } else {
      setIsAuthorSaved(false)
    }
  }, [author, user])

  const handleSaveAuthorClick = () => {
    if (user) {
      saveAuthor(author)
    }
  }

  const handleRemoveAuthorClick = () => {
    if (user) {
      removeAuthor(author)
    }
  }

  return (
    <Page title={author.name}>
      <div>
        <p>{author.name}</p>
      </div>
      <div>
        {/* if user has saved this post, show remove option */}
        {isAuthorSaved ? (
          <button onClick={handleRemoveAuthorClick}>Remove Author</button>
        ) : (
          <button onClick={handleSaveAuthorClick}>Save Author</button>
        )}
      </div>
      <br />
      <div>
        {author._rawBiography && <BlockContent blocks={author._rawBiography} />} 
      </div>
      <br />
      <div>
        <p>Posts</p>
        {author.posts.nodes.map(post => (
          <div key={post._id}>
            <Link to={post.slug}>{post.postTitle}</Link>
          </div>
        ))}
      </div>
    </Page>
  )
}

export default AuthorTemplate

export const query = graphql`
  query($id: String!) {
    sanityAuthor(id: { eq: $id }) {
      id
      slug
      name
      _rawBiography
      posts {
        nodes {
          _id
          postTitle
          slug
        }
      }
    }
  }
`
